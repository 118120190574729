import React from 'react';

function Services() {
  return (
    <section id="services" style={{ padding: '2rem', background: '#606c5a', textAlign: 'center' }}>
      <h2 style={{ color: '#f3f0e8', fontFamily: 'Garet-bold' }}>Our Services</h2>
      <p style={{ color: '#f3f0e8' }}>
        At Kaizen Studioz, we offer a range of professional barbering services designed to keep you looking sharp.
      </p>
      <div style={{ marginTop: '2rem', color: '#f3f0e8' }}>
        <ul style={{ listStyle: 'none', padding: 0, fontSize: '1.2rem', lineHeight: '2rem' }}>
          <li>✂️ Long Hair Haircuts</li>
          <li>💈 Fades and Taper Cuts</li>
          <li>🧔 Beard Trims and Line-Ups</li>
          <li>✨ Scissor Cuts and Styling</li>
          <li>🧖 Hot Towel Shaves</li>
        </ul>
      </div>
    </section>
  );
}

export default Services;
