import React from 'react';

function Header() {
  return (
    <header style={{
      background: '#f3f0e8',
      color: 'black',
      padding: '1rem',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    }}>
      <h1 style={{ color: 'black', fontFamily: 'Garet-bold' }}>KAIZEN STUDIOZ</h1>
      <nav>
        <a href="#home" style={{ margin: '0 1rem', textDecoration: 'none', color: 'black' }}>Home</a>
        <a href="#barbers" style={{ margin: '0 1rem', textDecoration: 'none', color: 'black' }}>Barbers</a>
        <a href="#services" style={{ margin: '0 1rem', textDecoration: 'none', color: 'black' }}>Services</a>
      </nav>
    </header>
  );
}

export default Header;
