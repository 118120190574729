import React from 'react';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Barbers from './components/Barbers';
import Booking from './components/Booking';
import './App.css';

function App() {
  return (
    <div>
      <Header />
      <Home />
      <Barbers />
      <Booking />
      <Footer />
    </div>
  );
}

export default App;
