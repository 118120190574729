import React from 'react';
import blendatomy from '../assets/pics/blendatomy.jpeg'; // Import the image
import manny from '../assets/pics/Manny.jpeg'
const barbersData = [
  {
    name: 'Michael Morales',
    image: blendatomy, // Replace with your image path
    about: 'Owner',
    instagram: 'https://www.instagram.com/blendatomy/profilecard/?igsh=MWQ1ZGUxMzBkMA==',
    booking: 'https://getsqr.co/michael-morales-4', // Replace with the booking link
  },
  {
    name: 'Manny',
    image: manny, // Replace with your image path
    about: '',
    instagram: 'https://www.instagram.com/mannyloc_98/profilecard/',
    booking: 'https://getsquire.com/booking/book/kaizen-studioz-san-dimas/barber/manuel-sanchez-1/services', // Replace with the booking link
  },
];

function Barbers() {
  return (
    <section id="barbers" style={{ padding: '1rem', background: '#b9b99d' }}>
      <h2
        style={{
          color: 'black',
          textAlign: 'center',
          fontFamily: 'Garet-bold',
          fontSize: 35,
        }}
      >
        OUR TEAM
      </h2>
      <div
        style={{
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'space-around',
        }}
      >
        {barbersData.map((barber, index) => (
          <div
            key={index}
            style={{
              position: 'relative',
              width: '300px',
              height: '400px',
              margin: '1rem',
              borderRadius: '10px',
              overflow: 'hidden',
              boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            }}
          >
            {/* Barber Image */}
            <img
              src={barber.image}
              alt={barber.name}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
              }}
            />
            {/* Book Now Button */}
            <a
              href={barber.booking}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                position: 'absolute', // Position at the top-right
                top: '10px', // Spacing from the top
                right: '10px', // Spacing from the right
                display: 'inline-flex',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '0.4rem 0.8rem',
                color: '#f3f0e8',
                borderRadius: '10px',
                textDecoration: 'none',
                fontSize: '0.9rem',
                fontFamily: 'Garet-bold',
                backgroundColor: 'rgba(0, 0, 0, 0.7)', // Add a solid background for visibility
                zIndex: 2, // Ensure it appears above other elements
                transition: 'background-color 0.3s',
              }}
              onMouseOver={(e) =>
                (e.target.style.backgroundColor = 'rgba(0, 0, 0, 1)')
              }
              onMouseOut={(e) =>
                (e.target.style.backgroundColor = 'rgba(0, 0, 0, 0.7)')
              }
            >
              Book Now
            </a>
            {/* Gradient Overlay */}
            <div
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                height: '75%',
                background: 'linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 1))', // More pronounced gradient
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                padding: '1rem',
              }}
            >
              <h3
                style={{
                  color: '#f3f0e8',
                  fontFamily: 'Garet-bold',
                  fontSize: '1rem', // Smaller text
                  margin: 0,
                }}
              >
                {barber.name}
              </h3>
              <p
                style={{
                  color: '#f3f0e8',
                  fontFamily: 'Garet-bold',
                  fontSize: '0.85rem', // Smaller text
                  margin: 0,
                }}
              >
                {barber.about}
              </p>
              <div style={{ display: 'flex', alignItems: 'center', marginTop: '0.5rem' }}>
                {/* Instagram Link */}
                <a
                  href={barber.instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    color: '#f3f0e8',
                    marginRight: '0.5rem',
                    textDecoration: 'none',
                    fontSize: '1.2rem', // Smaller icon
                  }}
                >
                  <i className="fab fa-instagram"></i>
                </a>

              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}

export default Barbers;
