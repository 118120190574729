import React from 'react';
import background from '../assets/pics/kaizen-home-lobby.jpg';
import logo from '../assets/pics/kaizen-tran-logo.png'; // Import the logo image

function Home() {
  return (
    <section
      id="home"
      style={{
        textAlign: 'center',
        padding: '2rem',
        backgroundImage: `linear-gradient(rgba(255, 255, 255, 0.4), rgba(0, 0, 0, 0.8)), url(${background})`, // Add semi-transparent white overlay
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        color: 'white',
      }}
    >
      <img
        src={logo}
        alt="Zen environment"
        style={{
          width: '60%',
          marginBottom: -50,
        }}
      />
      <div>
      <p style={{color: '#f3f0e8', fontFamily: 'Garet-bold', marginBottom: -20}}>
          Walk-ins welcome, appointments recommended
        </p>
        <p style={{color: '#f3f0e8', fontFamily: 'Garet-bold'}}>
        Open 7 days a week
        </p>
        <a
          href="https://www.instagram.com/kaizen.studioz/profilecard/?igsh=OGQ5ZDc2ODk2ZA=="
          target="_blank"
          rel="noopener noreferrer"
          style={{
            margin: '0 1rem',
            color: '#f3f0e8',
            textDecoration: 'none',
            fontSize: '2rem',
          }}
        >
          <i className="fab fa-instagram"></i>
        </a>
        <a
          href="https://www.tiktok.com/@kaizen.studioz?_t=8rXSWU6HocB&_r=1"
          target="_blank"
          rel="noopener noreferrer"
          style={{
            margin: '0 1rem',
            color: '#f3f0e8',
            textDecoration: 'none',
            fontSize: '2rem',
          }}
        >
          <i className="fab fa-tiktok"></i>
        </a>
        
        <p>
        <a
        href="https://getsquire.com/booking/brands/kaizen-studioz" // Replace with your Squire link
        target="_blank"
        rel="noopener noreferrer"
        style={{
          display: 'inline-block',
          padding: '1rem 2rem',
          fontSize: '1.2rem',
          fontFamily: 'Garet-bold',
          color: '#f3f0e8',
          backgroundColor: '#606c5a',
          borderRadius: '8px',
          textDecoration: 'none',
          boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
          transition: 'background-color 0.3s, transform 0.2s',
        }}
        onMouseOver={(e) => (e.target.style.backgroundColor = '#b0b9a8')}
        onMouseOut={(e) => (e.target.style.backgroundColor = '#606c5a')}
        onMouseDown={(e) => (e.target.style.transform = 'scale(0.95)')}
        onMouseUp={(e) => (e.target.style.transform = 'scale(1)')}
      >
        Book Appointment
      </a>
        </p>
        <p>
          <a
            href="https://www.google.com/maps?q=671+E+Bonita+Ave,+STE+B,+San+Dimas,+CA+91773"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              color: '#f3f0e8',
              textDecoration: 'underline',
              fontFamily: 'Garet-bold',
            }}
          >
            671 E Bonita Ave, STE B, San Dimas, CA 91773
          </a>
        </p>
      </div>
    </section>
  );
}

export default Home;
