import React from 'react';

function Footer() {
  return (
    <footer style={{ background: '#dcb482', color: 'black', padding: '1rem', textAlign: 'center' }}>
      <p>671 E Bonita Ave, STE B, San Dimas, CA 91773</p>
      <p>© 2024 Kaizen Studioz. All rights reserved.</p>
    </footer>
  );
}

export default Footer;
